// const { NULL } = require("sass");

const products = {
  thow: 'Tiny House on Wheels',
  th: 'Tiny House',
  mini: 'Minihäuser',
  modul: 'Modulhäuser',
  modulOW:' Modulhäuser on Wheels',
  trailer: 'Anhänger',
  shell: 'Rohbau',
  planing: 'Planungen',
  caravan: 'Wohnwagen',
  woodWagon: 'Holzwägen',
  roundWagon: 'Rundwagen',
  houseFlowed: 'Hausflöße',
  mobileHomes: 'Mobile Homes',
  expandableHouse: 'Ausbauhaus',
  garden: 'Gartenhäuser',
  else: 'Sonstige'
}


module.exports = [
  {
  name: 'TechTinyHouse',
  address: 'Köpfertstr. 23, 70599 Stuttgart',
  coordinates: [51.90546879172069, 8.87687827976001],
  email: 'team@techtinyhouse.de',
  url: 'http://www.techtinyhouse.de',
  products: [products.thow, products.mini, products.modul],
  length: '4m - 10,5m',
  customMade: true,
  modularDesign: false,
  buildingShell: true,
  autarky: true,
  prices: [['35000', 'Rohbau']],
  features: ['Stabilität (Statik)', 'Individualität', 'Werthaltigkeit (Materialwahl)'],
  featureDesc:[],
  description: `Konstruktion und Produktion von Tiny Houses nach Ingenieurspraxis. 
    Alle Komponenten des Rohbaus sind bzgl. Stand, Fahrt und mehr statisch berechnet. 
    Der Fokus liegt auf Stabilität, Leichtbau, Werthaltigkeit. Das Design reicht von traditionell bis modern. 
    Neben Tiny Houses werden Workshops, Statikberechnung und Beratung angeboten. (<i>Verfasser: TechTinyHouse</i>)`
},
{
  name: 'Wohnwagon',
  email: 'WERKSTATT@WOHNWAGON.AT',
  url: 'http://www.wohnwagon.at',
  address: 'Arnethgasse 42, 1160 Wien',
  coordinates: [48.214357, 16.319626],
  products: [products.thow, products.mini, products.planing],
  useSpace: '15m² - 30m²',
  customMade: true,
  modularDesign: true,
  buildingShell: true,
  autarky: true,
  prices: [['50000', 'Rohbau'], ['120000', 'schlüsselfertig']],
  features: ['Ökologische Baumaterialien', 'Inkl. Straßenzulassung', 'Auf Wunsch vollautark', 'Probewohnen möglich' ],
  featureDesc:[],
  description: `Wir möchten mit inspirierenden Vorzeigeprojekten wie unserem WOHNWAGON, 
  einem mobilen Tiny House, zeigen, dass wir bereits alle Puzzlesteine für ein gutes, nachhaltiges Leben in der Hand halten! 
  Wir unterstützen aber auch mit Planung & Beratung größere Projekte vom Einfamilienhaus bis zur autarken Siedlung und bieten 
  unsere Autarkielösungen im Webshop an. (<i>Verfasser: Wohnwagon</i>)`
},
{
  name: 'Rolling Tiny House',
  email: 'peter@rolling-tiny-house.de',
  url: 'https://rolling-tiny-house.de',
  address: 'Hüttenkamp 12, 24536 Neumünster',
  coordinates: [54.117316, 9.998770],
  visitPossible: true,
  overnight: true,
  products: [products.thow],
  useSpace: '18m² - 57m²',
  customMade: true,
  modularDesign: true,
  buildingShell: false,
  autarky: true,
  prices: [['44900', 'unmöbeliert'], ['49900', 'vollmöbeliert']],
  features: ['Grundstücke und Finanzierung', 'Wärmeschutznachweis gem. EnEV2016', 'sehr umfangreiche Website', 'Probewohnen möglich'],
  featureDesc:[],
  description: `Der Geschäftsführer Peter Pedersen legt nach eigener Aussage großen Wert darauf, dass seine 
    Häuschen ihren Besitzern lange erhalten bleiben und alle rechtlichen Voraussetzungen erfüllen, 
    die ein gutes Tiny House erfüllen sollte. Deswegen berücksichtigt, so Geschäftsführer Peter Pedersen, 
    Rolling Tiny House nicht nur den Wohlfühlfaktor eines Tiny Houses, sondern auch wichtige Dinge wie Baustatik, 
    Statik der Karosserie, EnEv und eine sinnvolle Belüftung. 
    (<i>Verfasser: Tiny House Helden basierend auf Telefonat mit Geschäftsführer Peter Pedersen</i>)`
},
// {
//   name: 'TinyHouseDesign', // Wollte entfernt werden. Siehe E-Mail
//   email: 'info@bohrdesign.de',
//   url: 'https://www.tiny-house-design.de',
//   address: 'Eichenweg 20, 17209 Wredenhagen',
//   coordinates: [53.276250, 12.506449],
//   visitPossible: true,
//   overnight: true,
//   products: [products.thow],
//   width: 'bis 2,55m',
//   weight: 'bis 3,5t',
//   // length 
//   customMade: true, 
//   modularDesign: false,
//   buildingShell: true,
//   // autarky
//   prices: [['46000', 'schlüsselfertig']],
//   features: ['Probewohnen möglich', 'Spezialisierung auf individuelle Wünsche', 'Ökologisch und nachhaltig' ],
//   featureDesc:[],
//   description: `Die Tiny Houses werden von TinyHouseDesign entworfen und in Zusammenarbeit mit der Tischlerei
//     Brüsehafer geplant und realisiert. Beim Bau werden laut Aussage des Herstellers "ökologische, nachhaltige und
//     regenerative Apsekte" berücksichtigt. (<i>Verfasser: Tiny House Helden</i>)<br><br>
//     "Ein Tiny House ist so individuell wie du selber, deshalb ist es schwierig einen 
//     Preis anzugeben. Dein Tiny House wird nach deinen Bedürfnissen gebaut.
//      Materialauswahl, Größe und Ausstattung bestimmen den Preis. 
//      Sprechen wir über deinen Traum vom Tiny House"(<i>TinyHouseDesign</i>)`
// },
{
  name: 'Tischlerei Bock',
  email:'info@bock-tischlerei.de',
  url: 'https://www.bock-tiny-house.de',
  address: 'Die Mühlenhöfe 1, 34537 Bad Wildungen-Braunau',
  coordinates: [51.079877, 9.125835],
  since: 2013,
  visitPossible: true,
  products: [products.thow],
  width: 'bis 3m',
  weight: 'über 3,5t',
  length: '5m - 6m',
  customMade: true,
  modularDesign: false,
  buildingShell: true,
  // autarky
  prices: [['28000', 'Rohbau'], ['50000', 'schlüsselfertig']],
  features: ['Ökologische, nachhaltige Materialien ', 'Fokus auf Individuallösungen', 'Baut Tiny Houses seit 2013/14', 'Eigener Tiny House Anhänger'],
  featureDesc:[],
  description: `Eine kleine Tischlerei aus Hessen, die mit natürlichen Rohstoffen und einer 
    diffusionsoffenen Bauweise wirbt, die für ein "angenehmes Klima" und eine "behagliche Atmosphäre" sorgen soll. (<i>Verfasser: Tiny House Helden</i>)`
},
{
  name: 'Tiny House Manufaktur',
  email: 'info@tiny-house-manufaktur.de',
  url: 'http://www.tiny-house-manufaktur.de/',
  address: 'Am Springborn 1, 51063 Köln',
  coordinates: [50.976690, 7.020200],
  products: [products.thow],
  length: '6,50m - 8,40m',
  customMade: true,
  // buildingShell
  // autarky
  prices: [['48000', 'Rohbau'], ['77000', 'schlüsselfertig']],
  features: ['Verwendung von heimischen Hölzern', 'Verwendung von Ölen, Wachsen und Lehmfarben', 'Natürliche Dämmstoffe'],
  featureDesc:[],
  description: `"Qualität hat für uns vor allen anderen Dingen Vorrang.
    Perfekte Harmonien realisieren und all dies meisterhaft umzusetzen – dafür steht die Tiny House Manufaktur!" 
    (Quelle: Tiny House Manufaktur Website) Weiterhin setzt der Hersteller laut eigener Angabe großen Wert auf stimmiges Design und einen
    ansprechenden Gesamteindruck. (<i>Verfasser: Tiny House Helden</i>)`
},
{
  name: 'Baluchon',
  email: 'tinyhouse.baluchon@gmail.com',
  url: 'http://www.tinyhouse-baluchon.fr',
  address: 'Za Les Petits Primeaux 5, 44330 Le Pallet, Frankreich',
  coordinates: [47.150864, -1.361924],
  products: [products.thow],
  // length:
  customMade: true,
  buildingShell: true,
  // autarky
  prices: [['35000', 'Rohbau'], ['65000', 'schlüsselfertig']],
  features: ['Hersteller aus Frankreich', 'Team aus Designern und Schreinern', 'Unterstützt Selbstbauprojekte', 'Liefert an Grenzländer' ],
  featureDesc:[],
  description: `Wir müssen zugeben, dass uns die Häuschen vom französischen Hersteller Baluchon optisch wirklich gut gefallen. 
  Der Hersteller scheint großen Wert auf das Design zu legen. 
  Das Team spricht auch Englisch. Habt also keine Scheu, wenn ihr kein Französisch sprecht. Übrigens handelt es sich bei dem Tiny House,
  das ihr hier oben auf der Website seht (im "Header"), um ein Häuschen von Baluchon. (<i>Verfasser: Tiny House Helden</i>)`
},
{
  name: 'Koersmann GmbH',
  url: 'https://www.koersmann.de/',
  email: 'info@koersmann.de',
  address: 'Tallinner Str. 39c, 48455 Bad Bentheim',
  coordinates: [52.313929, 7.072114],
  visitPossible: true,
  overnight: true,
  since: 2017,
  products: [products.thow, products.trailer],
  weight: 'über 3,5t',
  width: 'bis 3m',
  length: '5m - 12m',
  customMade: true,
  modularDesign: false,
  buildingShell: true,
  autarky: true,
  prices: [['25000', 'Rohbau'], ['40000', 'schlüsselfertig']],
  features: ['Ökologische Baustoffe', 'Fußbodenheizung', 'Aluminiumfassade', 'Bundesweiter Lieferservice' ],
  featureDesc:[],
  description: `Wir bauen ein Tiny House nach Ihren Wünschen. Unser erfahrenes Tischlerteam steht 
    Ihnen mit aller Kompetenz zur Verfügung. Starten Sie noch heute, um Ihr Haus mit uns zu planen. 
    Wir sind Ihr Tiny House Anbieter. <i>(Verfasser: Koersmann)</i>`
},
{
  name: 'Kleiner Nomade',
  email:'info@kleinernomade.org',
  url: 'https://www.kleinernomade.org/',
  address: 'Jakob-Reiserstraße 10, 72574 Bad Urach',
  coordinates: [48.482681, 9.463951],
  products: [products.thow, products.mini],
  length: '5m - 7,2m',
  customMade: true,
  modularDesign: true,
  buildingShell: true,
  autarky: true,
  prices: [['27000', 'Rohbau'], ['54000', 'schlüsselfertig']],
  features: ['Ökologisch und nachhaltig', 'Bauantragsservice', 'Bundesweiter Lieferservice', 'Probewohnen möglich' ],
  featureDesc:[],
  description: `In unserem ganzheitlichen und kosequenten Konzept in Punkto Ökologie, Nachhaltigkeit 
    und Regionalität, stellen wir einen hohen Anspruch an Qualität und Langlebigkeit unserer 
    Tiny Houses.<i>(Verfasser: Kleiner Nomade)</i>`
},
{
  name: 'Holzbau Pletz',
  email: 'verkauf@zirkuswagenbau.net',
  url: 'https://www.zirkuswagenbau.net',
  address: 'Isarhofen 12A, 94496 Ortenburg',
  coordinates: [48.567565, 13.207042],
  products: [products.thow, products.mini, products.caravan],
  length: '6,1m - 10m',
  customMade: true,
  modularDesign: false,
  buildingShell: false,
  autarky: true,
  prices: [['27999', 'Basismodell'], ['39499', 'schlüsselfertig']],
  features: ['100 % made in Germany', '40 Jahre Dachhersteller-Garantie', 'TÜV Süd Straßenzulassung', 'Individuelle Planung & Bau' ],
  featureDesc:[],
  description: `Die Zimmerei Pletz mit seinen Team bauen verschiedene Tiny Houses für 
    mobile Nutzung im Alltag. Ob kostengünstige und kleine Tiny Houses mit neuen Wohnideen oder 
    große & barrierefreie Tiny Häuser mit 9-10 Meter Pritschenlänge.<i>(Verfasser: Holzbau Pletz)</i>`
},
{
  name: 'Rolling Homes',
  email: 'info@rollinghomes.de',
  url: 'https://www.rollinghomes.de/',
  address: 'Mariannenstr. 27, 04315 Leipzig',
  coordinates: [51.347255, 12.401683],
  products: [products.woodWagon],
  length: '8m - 12m',
  customMade: true,
  modularDesign: false,
  buildingShell: true,
  autarky: true,
  prices: [['50000', 'Rohbau'], ['80000', 'schlüsselfertig']],
  features: ['2 Individuelle Erker mit 12m²', 'Überbreite und 12m Länge', 'Raumhöhen von 2,7m', 'Nur individuelle Aufträge' ],
  featureDesc:[],
  description: `Wir fertigen individuelle Holzwagen in traditioneller und gesunder 
  Holzrahmenbauweise. Sie haben maximale Fläche in unseren Wägen mit 45m² dank zwei ausfahrbarer Erker. 
  Diese lockern den Grundriss auf und schaffen abgetrennte Räume. Unsere Wagen sind 3,5m breit mit einer 
  Raumhöhe von 2,7m. Lassen Sie sich von uns individuelle beraten zu Ihrem Projekt! Sie können in unserem 
  neugebauten Oberlichtwagen übernachten um ein Gefühl zu bekommen für das Leben in einem Wagen.
  <i>(Verfasser: Rolling Homes)</i> `
},
{
  name: 'Andreas Beutel',
  email: 'info@tinyhaus-deutschland.de',
  url: 'https://www.tinyhaus-deutschland.de',
  address: 'Freiheitsweg 12b, 14772 Brandenburg an der Havel',
  addressDisplayed: '14772 Brandenburg a.d.H',
  coordinates: [52.448980, 12.542689],
  visitPossible: true,
  // overnight
  since: 2016,
  products: [products.thow, products.houseFlowed],
  weight: 'bis 3,5t', 
  width: 'bis 2,55m',
  length: 'bis 7.20m ',
  customMade: false,
  modularDesign: false,
  buildingShell: false,
  autarky: true,
  prices: [['35000', 'Wohnanhänger'], ['40000', 'Vollaustattung']],
  features: ['Wohnanhängerzulassung', 'Wassertank', 'Abwassertank', 'Solar' ],
  featureDesc:[],
  description: `Fertigung, Verkauf und Vermietung von Tiny-House-Wohnanhängern, sowie Hausflößen
  und anderen, beweglichen Freizeitgeräten.<i>(Verfasser: Andreas Beutel)</i>`
}, 

{
  name: 'Weg-Weiser Rundwagen',
  email: 'info@rundwagen.de',
  url: 'https://www.rundwagen.de/',
  address: 'Am Sportplatz 28, 79112 Freiburg',
  coordinates: [48.004209, 7.721682],
  visitPossible: true,
  overnight: false,
  since: 2012,
  products: [products.thow, products.roundWagon],
  length: '8m - 12m',
  customMade: true,
  modularDesign: false,
  buildingShell: true,
  autarky: true,
  prices: [['70000', 'schlüsselfertig']],
  features: ['Ökologische, nachhaltige Materialien', 'Individuelle Lösungen und Sonderbau'],
  featureDesc:[],
  description: `Die Firma Weg-Weiser wurde im Jahr 2013 gegründet. Seitdem wurde der 
  Rundwagen immer weiter entwickelt. Wir beziehen alle Rohstoffe aus dem Schwarzwald und 
  bleiben auch bei den Zulieferern in unserer Region. Der ökologische Fußabdruck ist uns wichtig.
  <i>(Verfasser: Weg-Weiser Rundwagen)</i>`
},
{
  name: 'Tiny House Allgäu',
  email: 'info@tiny-house-allgaeu.de',
  url: 'https://www.tiny-house-allgaeu.de/',
  address: 'Am Werkhaus 18, 87480 Weitnau' ,
  coordinates: [47.65918452366015, 10.089707668633736],
  visitPossible: true,
  overnight: false,
  products: [products.thow, products.mobileHomes, products.modul],
  weight:'über 3,5t',
  width: 'bis 3m',
  length: '5m - 10m',
  customMade: true,
  modularDesign: false,
  buildingShell: true,
  autarky: true,
  prices: [['28500', 'Rohbau'], ['65000', 'schlüsselfertig']],
  features: ['Individuelle Planung & Fertigung', 'Bau mit Liebe zum Detail', 'Straßenzulassung auch über 3,5t', 'Häuser als Mobile Home möglich' ],
  featureDesc:[],
  description: `Wir bauen Ihnen Ihr ganz individuelles Tiny House mit Herz. 
  Mit einer hochwertigen Bausubstanz, Ausstattung, viel Liebe zum Detail und einer 
  sorgfälltigen Verarbeitung nach deutscher Handwerkertradition. Eintreten und Wohlfühlen. 
  Dabei sind Tiny Houses mit Straßenzulassung unter und über 3,5t genauso möglich, wie eine 
  "Mobile Home"-Variante (Transport via Tieflader). 
  Wir freuen uns auf Ihre Anfrage!<i>(Verfasser: Tiny House Allgäu)</i>`
},
// {
//   name: 'Tiny Houses Company Ltd',
//   email: 'tinyhouses@web.de',
//   url: 'http://tinyhousescompany.de/',
//   address: 'Hinter der Schule 12, 31241 Ilsede',
//   coordinates: [52.280389, 10.193781],
//   visitPossible: '',
//   since: 2017,
//   products: [products.thow],
//   weight: '',
//   length: '',
//   width: '',
//   useSpace: '',
//   customMade: '',
//   modularDesign: '',
//   buildingShell: '',
//   autarky: '',
//   prices: [[0, ''], [0, '']],
//   features: ['Unternehmen existiert nicht mehr'],
//   featureDesc:[],
//   description: `Laut eigener Aussage des Herstellers existiert dieses Unternehmen nicht mehr.
//     <i>(Verfasser: Tiny House Helden)</i>`
// },
{
  name: 'Holzdesign Hegselmann',
  email: 'info@holzdesign-hegselmann.de',
  url: 'https://www.facebook.com/Tiny-House-by-Holzdesign-Hegselmann-1785626598402467/',
  address: 'Foerster-Blanke-Str. 61, 33154 Salzkotten',
  coordinates: [51.629973, 8.668334],
  products: [products.thow, products.mobileHomes, products.expandableHouse],
  length: '6m - 9m',
  customMade: true,
  modularDesign: false,
  buildingShell: true,
  autarky: true,
  prices: [['30000', 'auf Anfrage']],
  features: ['Ökologische Bauweise', 'Qualitative Materialauswahl', 'moderne Fassadengestaltung', 'Fassaden ohne Pflegeaufwand', 'Probewohnen möglich' ],
  featureDesc:[],
  description: `Holzdesign Hegselmann ist Ihr kompetenter Partner für individuelle, kleine Wohnraumlösungen. 
    Spezialisiert sind wir auf Tiny House on Wheels, Mobilheim und Modulbauweise. <i>(Verfasser: Holzdesign Hegselmann)</i>`
},

{
  name: 'Tiny House Koopmann',
  email: 'koopmann.christian@gmx.de',
  url: 'https://www.tiny-house-koopmann.de/',
  address: 'Gottlieb-Daimler-Str. 7, 26169 Friesoythe',
  coordinates: [53.015147, 7.887926],
  visitPossible: true,
  overnight: true, 
  since: 2018,
  products: [products.thow, products.mini, products.mobileHomes],
  weight: 'über 3,5t',
  width: ' bis 2,55m',
  length: '6m - 12m',
  customMade: true,
  modularDesign: false,
  buildingShell: true,
  autarky: true,
  prices: [ ['50000', 'Rohbau'], ['75000', 'schlüsselfertig'] ],
  features: ['Ökologisch und nachhaltig', 'Baugenehmigungsservice', 'ganz individuell', 'Probewohnen möglich', 'Bundesweite Lieferung möglich' ],
  featureDesc:[],
  description: `Der Wunsch vom eigenen Haus ist für viele ein sehr großer. Doch ein solches Projekt stellt die meisten Menschen vor eine starke finanzielle Belastung, die einen den Großteil des Lebens begleitet. 
  Immer mehr Menschen setzen deshalb auf ein Tiny House. Dieses ist hochwertig, preiswert und auf Wunsch auch beweglich und bietet mehr Selbstbestimmtheit als herkömmliche Häuser. Ich möchte Sie auf Ihrem Weg in die Unabhängigkeit begleiten und Ihnen Ihr individuelles Tiny House bauen.
  <i>(Verfasser: Tiny House Koopmann)</i>`
},
{
  name: 'Tiny House Diekmann',
  email: 'tinyhouse@diekmann-schreinerei.de',
  url: 'https://www.tiny-house-diekmann.de',
  address: 'Warendorfer Str. 10, 59075 Hamm',
  coordinates: [51.697260, 7.774851],
  since: 2015,
  visitPossible: true,
  overnight: true,
  products: [products.thow, products.modulOW],
  width: 'bis 3m',
  weight:' bis 3,5t',
  length: '5,40m – 7,80m',
  customMade: true,
  modularDesign: false,
  buildingShell: false,
  autarky: true,
  prices: [ ['35000', 'Rohbau'], ['45000', 'schlüsselfertig'] ],
  features: ['Probewohnen möglich, bundesweit', 'Vermietung von Tiny Houses', 'Tiny Houses für gewerbl. Zwecke', 'Architekt u. Designer Inhouse' ],
  featureDesc: [
    'Sie möchten einmal ausprobieren, wie sich das Wohnen in einem Tiny House anfühlt ? Dann buchen Sie doch einfach an einem der Probewohn-Standorte unserer Partner ein paar Übernachtungen in einem unserer Tiny Houses.',
    'Sie suchen ein mobiles Büro oder einen temporären Ausstellungsraum ? Bei uns können Sie zwei Office-Tiny Häuser mieten, falls Sie vorübergehend einmal mehr Büroraum benötigen oder mit einem besonderen Pop-up-Store mobil sein wollen. ',
    'Wir sind Spezialisten für die gewerbliche Nutzung von Tiny Houses. Ob als Hotelzimmer, mobiler Shop oder Service-Station – wir setzen mit Ihnen Ihre rollende Geschäftsidee um.',
    'Unsere hauseigenen Fachexperten beraten und unterstützen Sie bei allen baurechtlichen und planerischen Fragen auf dem Weg zu Ihrem Wunsch-Tiny House.'
  ],
  description: `<i>ACHTUNG: Hersteller ist mittlerweile leider insolvent und hat die Produktion eingestellt!</i> <br><br> Tiny Houses sind mehr als nur ein Dach über dem Kopf – sie sind Heimat und Freiheit zugleich. Leben im Tiny House bedeutet bewusster Verzicht auf alles Überflüssige, um Platz zu schaffen für das Wesentliche. Mit unseren kreativen Ideen und der Erfahrung aus vielen Jahren Tiny House-Bau machen wir gemeinsam dein Tiny zu deinem ganz persönlichen Traumhaus. Schreinerei seit 1949, 40 Mitarbeiter davon 30 Tiny House Spezialisten, eigene Architekten/Designer und Planer für dein Tiny House Projekt.
  <i>(Verfasser: Tiny House Diekmann)</i>`
},
{
  name: 'Green Up GmbH',
  url: 'https://www.nimme.net/',
  email: 'servus@nimme.net',
  address: 'Wiedner Hauptstraße 65, 1040 Wien',
  coordinates: [48.189871, 16.366374],
  overnight: true,
  visitPossible: true,
  since: 2017,
  products: [products.th, products.modul],
  prices: [ ['25000', 'Rohbau'], ['40000', 'schlüsselfertig'] ],
  width: 'über 2,55m',
  weight: 'über 3,5t',
  length: '5m - 8m',
  useSpace: '12m² - 30m²',
  customMade: true,
  modularDesign: false,
  buildingShell: true,
  autarky: true,
  features: ['Fußbodenheizung', 'Schafwolle als Dämmung'],
  featureDesc:[],
  description: `Gegründet haben wir uns im Jahr 2017. Seitdem lassen wir mobile Tiny House individuell anfertigen. 
  Seit 2018 bieten wir auch Modulhäuser an.`
},
{
  name: 'Tiny House Rheinau',
  email: 'dieterpuhane@outlook.de',
  url: 'http://www.tiny-house-rheinau.de',
  address: 'Maierhof 7, 77866 Rheinau',
  coordinates: [ 48.687678, 7.978807],
  visitPossible: true,
  overnight: true,
  products: [products.thow, products.trailer],
  prices: [ ['24000', 'Rohbau'], ['55000', 'schlüsselfertig'] ],
  weight: 'bis 3,5t',
  length: '6m - 9m',
  width: '2,55m',
  useSpace: '15m² - 30m²',
  customMade: true,
  modularDesign: true,
  buildingShell: true,
  autarky: true,
  features: ['Ökologische Bauweise', 'Fußbodenheizung, Hubbett, etc.', 'Service für Baugesuche und TÜV', 'Probewohnen: 1x6m , 2x9m Haus' ],
  featureDesc:[],
  description: `Erster Tiny House Hersteller in Deutschland mit bisher weit über 100 gebauten Häusern. Viel Erfahrung beim Bau, beim Umgang mit Baubehörden und TÜV Dienststellen.
   Erfahrung die Sie nutzen sollten.  <i>(Verfasser: Tiny House Rheinau)</i>`
},
// { // removed because unsubscribed from mailchimp
//   name: 'Boxliving',
//   email: 'info@boxliving.de',
//   url: 'http://www.tiny-house-rheinau.de',
//   address: 'Steinweg 6, 35708 Haiger',
//   coordinates: [ 50.7925032, 8.2301867],
//   visitPossible: '',
//   overnight: '',
//   products: [products.thow],
//   prices: [ ['', ''], ['', ''] ],
//   weight: '',
//   length: '',
//   width: '',
//   useSpace: '',
//   customMade: '',
//   modularDesign: '',
//   buildingShell: '',
//   autarky: '',
//   features: ['' ],
//   featureDesc:[],
//   description: ``
// },
{
  name: 'Tiny Hepperle',
  url: 'https://tiny-hepperle.de/',
  email: 'info@tiny-hepperle.de',
  address: 'Erkenbergstr. 3, 73272 Neidlingen',
  addressDisplayed:'73272 Neidlingen',
  coordinates: [48.5797237404658, 9.565024340883653],
  products: [products.thow, products.mobileHomes, products.expandableHouse],
  prices: [ ['40000', 'Rohbau'], ['70000', 'schlüsselfertig'] ],
  overnight: true,
  weight:'ohne Beschränkung',
  length: 'bis 9m',
  width: 'bis 3m',
  useSpace: 'bis 21m²',
  customMade: true,
  modularDesign: false,
  buildingShell: true,
  autarky: false,
  features: ['Nachhaltige Baustoffe & Bauweise', 'Mobil auf kurzen Strecken', 'Made in Germany', 'Hochwertig & Top-Design' ],
  featureDesc:[
    'Die Baustoffe, welche bei uns zum Einsatz kommen werden vom Chef persönlich auf Ihre Qualität hin ausgewählt. Auf Wunsch verbauen wir Ihren eigenen Baum im neuen Heim. Chemische Stoffe gibt es bei uns nicht, damit ist ein Wohlfühl-Klima garantiert. Auf Wunsch sogar mit Strohdämmung.',
    'Wir haben ein einzigartiges System entwickelt, welches den Transport auf kurzen Strecken ermöglicht und dafür keinen Anhänger benötigt. Die hochgedämmte Bodenplatte dient hierbei als Fahrgestell.',
    'Wir produzieren ausschließlich in Neidlingen bei Stuttgart in unserem langjährig erfahrenen Zimmermeisterbetrieb. Unsere Partner sind ortsansässig und aus der näheren Umgebung.',
    'Wir garantieren Tiny Häuser in höchster Qualität geplant und gebaut vom Zimmermeisterbetrieb mit über 20 Jahren Erfahrung im Holzbau.'
  ],
  description: `Wir sind ein Zimmermeisterbetrieb in Neidlingen im Großraum Stuttgart mit über 20 Jahren Erfahrung im Holzbau. Wir bauen Tiny Houses und unser Sohn und Mitarbeiter Tim wohnt selbst in einem. Wir wissen worauf es ankommt.
  Durch die langjährige Erfahrung im Holzhausbau fertigen wir sinnvolle Häuser, welche auf Langlebigkeit ausgelegt sind und mit dem Rückbau unserer Umwelt keinen größeren Schaden zufügen. 
  Beim Bau aller Häuser ist ein ansprechendes Design für Sie besonders wichtig.   <i>(Verfasser: Tiny Hepperle)</i>`
},
{
  name: 'Wunderwuzzi', //ehemals tiny-to-go
  url: 'https://www.wunderwuzzi-tinyhouses.de',
  email: 'urlaub@tiny-to-go.de',
  address: 'Koppelstr.6a, 49434 Neuenkirchen',
  addressDisplayed:'49434 Neuenkirchen ',
  coordinates: [52.47809966727572, 8.096739713202231],
  products: [products.thow],
  prices: [ ['20000', 'Rohbau'], ['45000', 'schlüsselfertig'] ],
  overnight: true,
  weight:'bis 3,5t',
  length: 'bis 7,20m',
  width: '',
  useSpace: '',
  customMade: true,
  modularDesign: false,
  buildingShell: true,
  autarky: true,
  features: ['Fokus auf Individuallösungen', 'Probewohnen möglich', 'Fußbodenheizung', 'TÜV Zulassung' ],
  featureDesc:[
    '',
  'Unsere Tiny Houses stehen zum Probewohnen an mehreren Standorten in Deutschland bereit. Worpswede im Land of Greenam Alfsee in Rieste, Tiny Ferien- und Ausstellungspark Eurocamp-Spreewaldtor im schönen Spreewald in der Nähe von Lübbenau','',''],
  description: `Die Firma Wunderwuzzi (ehemals Tiny-to-go) wurde 2019 von <i>Torsten Bei der Kellen</i> und <i>Claudia Weitowitz</i> eigens für die Herstellung von Tiny Houses gegründet. 
  Der Tischlermeister <i>Torsten Bei der Kellen</i> ist seit mehr als 20 Jahren selbstständig und hat sich auf Einrichtungen spezialisiert. Zu den Kunden gehören Ärzte, aber auch Studentenwerken. Gerade bei Studentenwohnungen muss viel Einrichtung in kleinen Räumen untergebracht werden. Aus eigener Erfahrung weiss Herr <i>Bei der Kellen</i> worauf es in einem Tiny House ankommt. Er wohnt seit 1997 in einem Tiny House, das er selbst gebaut hat. Frau <i>Weitowitz</i> kümmert sich im Unternehmen um die Vermietung, Verwaltung und Vermarktung der Tiny Houses.   <i>(Verfasser: Wunderwuzzi)</i>`
},
{
  name: 'Green Mobile Home',
  url: 'https://www.greenmobilehome.de',
  email: 'info@greenmobilehome.de',
  address: 'Barbarossastraße 61, 63571 Gelnhausen',
  addressDisplayed:'63571 Gelnhausen',
  coordinates: [50.20177286417392, 9.20790065611161],
  products: [products.thow, products.modul],
  prices: [ ['20000', 'Bausatz'], ['39990', 'schlüsselfertig'] ],
  overnight: true,
  weight:'bis 3,5t',
  length: '3,4m - 18m',
  width: '2,55m - 8m',
  useSpace: '11m² - 150m²',
  customMade: true,
  modularDesign: true,
  buildingShell: true,
  autarky: true,
  features: ['Fußbodenheizung', 'Dämmung: Schafs-,Hanf-,Holzwolle', 'Autarkie Photovoltaikanlage', 'Beratung & europaweite Lieferung' ],
  featureDesc:[
    'Wir verwenden einen EcoFlim mit Carbonschicht und Kupferdrähten, die erwärmt werden.',
    'Auf Kundenwunsch können wir die gewünschte Dämmung anbieten. Seegras und Holzfaserdämmplatten sind möglich.',
    'Unsere Photovoltaikanlage besteht aus 4-6 Panels, 1 Wechselrichter, 1 Speicher. Autark-Paket mit Wassertanks, Pumpen, Gas/Photovoltaik, beheizten Technikraum möglich.',
    'Wir beraten Sie gerne kostenfrei und bieten Ihnen zusätzliche Services - Architektenleistungen, Fundament Arbeiten uvm. aus einer Hand. Lieferung inkl. 100km kostenlos Europaweit'
  ],
  description: `„Ein eigenes bezahlbares Heim kann für die Menschen so viel bedeuten“
  
  Unsere Gesellschaft wandelt sich und wird mobiler, Lebenssituationen ändern sich – verlangt werden flexible (Wohn)Lösungen. Diese Trends haben wir aufgegriffen und
  bieten neue, funktionelle und bezahlbare Wohnmodule sowie Tiny House on Wheels.
  
  Der Traum von den eigenen vier Wänden wird mit „Green Mobile Home“ Wirklichkeit – flexibel, ökologisch und zum erschwinglichen Preis. 
  
  Green Mobile Home ist ein innovatives, aufstrebendes und umweltbewusstes Unternehmen aus dem Main-Kinzig-Kreis mit Firmensitz in Gelnhausen. Wir haben uns auf den modularen Holzrahmenbau sowie Holzständerbauweise spezialisiert und sehen es als unsere Mission, nachhaltig und ökologisch zu bauen.
  
  Als Familienbetrieb sind wir immer persönlich für Sie da. Sie profitieren dabei von unserem persönlichen Engagement, fachlicher Kompetenz und Zuverlässigkeit im Detail. 
  Gerne verwirklichen wir Ihre Wünsche mit geprüften, wohngesunden Baustoffen und erprobter Qualität.   <i>(Verfasser: Green Mobile Home)</i>`
},
{
  name: 'PanHaus GmbH',
  url: 'https://www.panhaus.de',
  email: 'info@panhaus.de',
  address: 'Dorfstrasse 32 24640 Hasenmoor',
  addressDisplayed:'24640 Hasenmoor',
  coordinates: [53.90101400101047, 9.990698732144526],
  products: [products.thow, products.modul, 'Siedlungsprojekte'],
  prices: [ ['38000', 'Rohbau'], ['68000', 'schlüsselfertig'] ],
  overnight: true,
  weight:'bis 3,5t',
  length: 'bis 8,40m',
  width: 'bis 3m',
  useSpace: 'ab 22m²',
  customMade: true,
  modularDesign: false,
  buildingShell: true,
  autarky: true,
  features: ['diffusionsoffene Holzbauweise', 'Wandheizung, Gasbrennwerttherme', 'Fußbodenheizung', 'Individueller Aufbau & Gestaltung'],
  featureDesc:[
    "Unsere Häuser sind aus Baustoffen hergestellt, die Kondensfeuchtigkeit aus der Raumluft aufnehmen , speichern und nach draußen abgeben können! Das ist ein natürlicher baubiologischer Schutz vor Feuchtigkeitsproblemen im Wohnraum, Dach und Wänden",
    "Wir statten unsere Häuser mit einer Heizungsanlage aus, die auch ein Einfamilienhaus warm bekommt und können dadurch den Luxus von unbegrenzten Wärmemengen beim Heizen, Waschen und Duschen umsetzen.Wandheizungsflächen aus Warmwasserleitungen in Lehmputz bieten behagliche Strahlungswärme, saubere Atemluft mit optimalem Feuchtigkeitsgehalt und natürliche Baustoffe zum Anfassen. Lehm und Holz sind die natürlichen Garanten für wohngesundes Bauen und Leben! ",
    "",
    "",
    ""

  ],
  description: `  Als wir im Sommer 2019 für einen unserer Freunde privat ein erstes Tiny Haus bauten, sahen wir noch gar nicht, dass der erste Schritt getan war. Die Liebe zum Bauen ließ uns einfach starten, inspirierte uns. Autos fuhren langsamer, Kinder schauten zu, Leute kamen zum Klönen und die Presse schrieb erste kleine Artikel. Nach nur wenigen Wochen bekamen wir die ersten Anfragen von mutigen Tiny Haus Interessent*innen und zum Jahresanfang 2020 wurde klar: Wir sind PanHaus! 

  Wir erstellen kompakte Häuser mit hoher Variabilität - ob als Einzelhaus oder als Siedlungsprojekt für alle Altersgruppen - in wohngesunder diffusionsoffener Holzbauweise, unter Verwendung von natürlichen unbelasteten Baustoffen, nachhaltig und umweltschonend produziert, die zudem angenehm in der Verarbeitung und recycelbar sind. Wir konzentrieren 20 Jahre Erfahrung und Erfüllung aus dem Zimmerer Handwerk und individuellem Holzhausbau auf unsere Tiny Häuser.

  Raum für Ideen ist unser Motto, wir gestalten gemeinsam mit unseren Kund*innen als Kern unseres Bauteams.  <i>(Verfasser: PanHaus GmbH)</i>`
},
{
  name: 'Aurora Company',
  url: 'https://auroracompany.pl/en/',
  email: 'tiny.auroracompany@gmail.com',
  address: 'Rokiciny Podhalańskie 190 A, 34-721 Raba Wyżna',
  addressDisplayed:'Raba Wyżna (Polen)',
  coordinates: [49.574596866127806, 19.899810655868144],
  products: [products.thow],
  prices: [ ['29500', 'Rohbau'], ['49500', 'schlüsselfertig'] ],
  overnight: true,
  weight:'bis 3,5t',
  length: 'bis 7,20m',
  width: '2,55m',
  useSpace: 'ab 19m²',
  customMade: true,
  modularDesign: false,
  buildingShell: true,
  autarky: true,
  features: ['ökologische Dämmung', 'hochwertige Badewanne', 'Marquise', 'kostenlose Lieferung'],
  featureDesc:[
    "",
    "",
    "",
    ""

  ],
  description: `Wir bauen jährlich bis zu 100  Tiny Häuser. Jahrelange Erfahrung, die besten Materialien und ein großartiges Team zeichnet Aurora Company aus. Lass uns gemeinsam deinen Tiny House Traum verwirklichen
  <br><br>  
  Qualität ist etwas, in dem wir uns auszeichnen. Wir tun unser Bestes, um unsere Produkte ständig zu verbessern und innovative und praktische Lösungen zu finden. 
  <br><br> 
  Du hast die Qual der Wahl zwischen unseren 3 mobilen Tiny House Varianten:
  Porto / Sunshine / Verona
  <br><br> 
  Wir sind der größte Tiny House Hersteller in Polen und bauen ganzjährig bewohnbare Tiny Häuser.
  <br><br> 
  Eckdaten zum Tiny House Porto:
  Außenmaße: 7,20m x 2,55m x 3,95m
  Fläche: 13,2 m2 + 6,1 m2 Schlafzimmer im Obergeschoss
  Gewicht: ca. 3500 kg
  Gesamtfläche: 25,2 m² inkl. 2 Schlafebenen
  <br><br> 
  Eckdaten zum Tiny House Sunshine:
  Maße: 6,70m x 2,55m x 3,95m
  Gewicht: ca. 3500 kg
  Gesamtfläche: 20,5m2 inkl. 1 Schlafebene
  <br><br> 
  Eckdaten zum Tiny House Verona:
  Maße: 6,10m x 2,55m x 3,95m
  Gewicht: ca. 3500 kg
  Gesamtfläche: 16,7m2 + 2,5m2 Terasse
  <br><br> 
<i>(Verfasser: Aurora Company)</i>`
},
{
  name: 'Tiny House by Wöhltjen',
  url: 'https://www.tiny-house.info',
  email: 'kontakt@tiny-house.info',
  address: 'Carl-F.-W.-Borgward-Straße 12, 28832 Achim-Uphusen',
  addressDisplayed:'28832 Achim-Uphusen',
  coordinates: [53.02397953104571, 8.990401585001907],
  products: [products.modul, products.thow],
  prices: [ ['30000', 'Rohbau'], ['65000', 'schlüsselfertig'] ],
  overnight: true,
  visitPossible: true,
  weight:'bis 3,5t',
  length: '5m - 7,20m',
  width: '2,55m - 3m',
  useSpace: '12m² - 50m²',
  customMade: true,
  modularDesign: false,
  buildingShell: true,
  autarky: true,
  
  features:[
    'Individuallösungen',
    'Bau mit Liebe zum Detail',
    'Probewohnen möglich',
    'Vermietung von Tiny Häusern',
    'Tiny House für gewerbl. Zweck'
  ],
  featureDesc:[
    "",
    "",
    "",
    ""
  ],
  description: `Wir lieben Tiny Houses, weil wir unsere gesamte Kompetenz in allen Bereichen individuell einbringen und umsetzen können.  <i>(Verfasser: Tiny House by Wöhltjen)</i>`
},
{
  name: 'TOGO Tinyhouse',
  url: 'www.togo-tinyhouse.de',
  email: 'info@togo-tinyhouse.de',
  address: 'Florianstrasse 8, 79862 Höchenschwand',
  addressDisplayed:'79862 Höchenschwand',
  coordinates: [47.73154285584688, 8.174657999682852],
  products: [products.thow, products.modul, products.else],
  prices: [ ['80000', 'schlüsselfertig'] ],
  overnight: false,
  visitPossible: true,
  weight:'bis 3,5t',
  length: '5,40m - 8,40m',
  width: '2,55m',
  useSpace: '15m² - 28m²',
  customMade: true,
  modularDesign: false,
  buildingShell: false,
  autarky: true,
  
  features:[
    'Made in Black forest'
  ],
  featureDesc:[
    "Die Weißtanne aus dem Schwarzwald gibt TOGO Tiny houses ihren unverkennbaren Charakter. Auch bei der Dämmung setzen wir auf regionale und nachhaltige Produkte und verwenden deshalb Holzfaserdämmung aus der Region. Ein hoher Anspruch an Design und viel handwerkliches Geschick zeichnen unsere Arbeit aus."
  ],
  description: `Wir gestalten und fertigen Tiny houses in einem gesamt aufeinander abgestimmten Konzept. Die große Herausforderung dabei ist es, den begrenzten Raum so zu gliedern und einzurichten das alles möglichst praktikabel und platzsparend angeordnet ist. Dabei dürfen die technischen Anforderungen an ein Tiny house nicht außeracht gelassen werden. 
  <br><br>  
  Ein TOGO Tiny house ist nicht nur ein Raum mit Möbeln, es ist ein Gesamtkonzept vom Raum bis zum Möbel, es wirkt wie aus einem Guss. Durch die gleiche Materialität von Wand und Möbelfronten, soll der Geist des Menschen auf das Wesentliche gelenkt werden. Für uns bedeutet es in einem Tiny house zu leben, sich bewusst für ein minimalistisches Leben zu entscheiden, diesen Grundgedanken haben wir zu unserem Leitgedanken gemacht und uns als Gestaltungsgrundlage gesetzt. Klare Linien, großzügig und bewusst angeordnete Fensterflächen lassen den kleinen Raum groß wirken und geben dem Bewohner so das Gefühl frei zu sein.
  <br><br>  
  Ein auf den Raum abgestimmtes Lichtkonzept ermöglicht dem Nutzer, diverse Lichtstimmungen zu erzeugen, und trägt so zum gesamten Design des Tiny houses bei.  <i>(Verfasser: TOGO Tinyhouse)</i>`
},
{
  name: 'Winzig Wohnen Tiny House',
  url: 'www.winzig-wohnen.de',
  email: 'moin@winzig-wohnen.de',
  address: 'August-Wilhelm-Kühnholz-Straße 4, 26135 Oldenburg',
  addressDisplayed:'26135 Oldenburg',
  coordinates: [53.11225200104338, 8.258708403095495],
  products: [products.thow],
  prices: [ ['85000', 'Basismodel'] ],

  length: '5,40m - 8,40m',
  useSpace: '15m² - 22m²',
  width: '2,55m',
  weight:'bis 3,5t',

  // Markieren Sie die Aussagen, die auf Sie zutreffen
  customMade: true,
  modularDesign: false,
  buildingShell: false,
  autarky: true,
  
  features:[
    'Hochwertiges Design',
    'Eigene Herstellung',
    'Standsicherheitsnachweis/Statik',
    'Lieferung inklusive',
    'Made in Germany'
  ],
  featureDesc:[
    "Kompetente Architekten und Innenarchitekten planen und entwerfen Dein Tiny House. Unser Team aus erfahrenen Tischlern und Projektleitern stellt das Tiny House mit höchster Präzision in eigenen Werkstätten her. Von deiner Anfrage bis zur Auslieferung alles in einer Hand.",
    "",
    "Eine Statik sowie eine Straßenzulassung sind bei uns immer inklusive. Gerne unterstützen Dich unsere Architekten bei dem Baugenehmigungsverfahren.",
    "Selbstverständlich fahren wir Dir dein Tiny House an den gewünschten Stellplatz.",
    ""
  ],

  overnight: true,
  visitPossible: true,

  description: `Wir sind Tischler, Architekten und Ingenieure. Was wir lieben: Freiheit, Natur und Holz. Unser Ziel ist es, für dich das Tiny House zu bauen, das du dir immer gewünscht hast. Die Zeit zwischen den frühen Ideen und Entwürfen und unseren ersten, fertigen Tiny Houses ist schnell vergangen. Unsere Begeisterung ist dabei nur größer geworden. Und wenn du siehst, riechst und fühlst, was wir für dich geschaffen haben, dann wird dein Gefühl für diese Art zu leben sich genauso schnell in Liebe verwandeln wie bei uns.
  Unser Tiny House für dich ist Handmade in Germany: minimalistisch, nachhaltig durchdacht und mit natürlichem Charme. Oder kurz gesagt: ökologisch sinnvoll und schick. Mit individueller Beratung unterstützen wir dich dabei, deinen persönlichen Traum vom Tiny House zu entwickeln – um die die Verwirklichung kümmern wir uns. Wir freuen uns deine Anfrage! <i>(Verfasser: Winzig Wohnen Tiny House)</i>`
},
{
  name: 'Tiny Life',
  added: '31.10.2021',
  since: 2020,
  url: 'www.tiny-life.de',
  email: 'info@tiny-life.de',
  address: 'Birkenstr. 28, 85467 Niederneuching',
  addressDisplayed:'85467 Niederneuching',
  coordinates: [48.25209464400832, 11.830495512026767],
  products: [products.thow, products.modul, products.garden],
  prices: [ ['60000', ''] ],

  length: 'ab 6m',
  useSpace: '13m² - 80m²',
  width: '2,55m - 3,50m',
  weight:'bis 3,5t',

  // Markieren Sie die Aussagen, die auf Sie zutreffen
  customMade: true,
  modularDesign: false,
  buildingShell: false,
  autarky: false,
  
  features:[],
  featureDesc:[],

  overnight: false,
  visitPossible: false,

  description: `Herzlichen Willkommen bei Tiny Life.<br><br>
  Du bist auf der Suche nach einem individuellen Tiny- oder Modulhaus-dann bist Du hier genau richtig!"
  Unsere Modulhäuser sowie unsere Tiny Häuser auf Rädern
  richten sich an Menschen die ihren Lebensstil auf das Wesentliche reduzieren wollen und dabei nicht auf den Komfort eines hochwertig gebauten Gebäudes verzichten möchten.
  Das intelligente und flexible  Konzept hat viele Anwendungs- möglichkeiten, und legt vorallem Wert auf ein anspruchsvolles Design als Eigenheim, als Ferienhaus, oder als Rückzugsort für Freizeit oder Arbeit. Wir gestalten unsere Tiny Life Häuser nach dem Motto: Great life. Tiny Footprint. - in dem wir einen unabhängigen und nachhaltigen Wohnraum für dieZukunft schaffen. Entscheide Dich jetzt für mehr Freiheit und Flexibilät!
  
   <i>(Verfasser: Tiny Life)</i>`
},
{
  name: 'mlab.design',
  added: '06.10.2022',
  since: 2020,
  url: 'https://info.mlab.design/fl2/?utm_source=listing&utm_medium=listing&utm_campaign=fl2-1&utm_id=tinyhousehelden&utm_term=tinyhousehelden',
  email: 'contact@mlab.design',
  address: 'Talbothof 30, 52070 Aachen',
  addressDisplayed:'52070 Aachen',
  coordinates: [50.78911554268251, 6.086491327078626],
  products: ["Wohnmodule", "Mikrohäuser"],
  prices: [ ['145000', 'Gebäudehülle'], ['195000', 'Bezugsfertig'] ],

  length: 'bis 11,2m',
  useSpace: 'ab 33m²',
  width: '4m',
  weight:'bis 3,5t',

  // Markieren Sie die Aussagen, die auf Sie zutreffen
  customMade: true,
  modularDesign: true,
  buildingShell: true,
  autarky: true,
  
  features:["konsequent Nachhaltig", "nachträglich erweiterbar", "doppelt langlebig", "stabil & stapelbar", "Fokus auf Design"],
  featureDesc:[
    "Die Aspekte Langlebigkeit, umweltverträgliche Produktion, Betriebs- und Wartungsaufwand sowie die Verwertung wurden bei jedem Bauteil sorgfältig abgewägt. Darüber hinaus haben die im #FL2 verbauten Bäume der Atmosphäre mehrere Tonnen CO2 entzogen und speichern den Kohlenstoff dauerhaft. Deswegen weist jedes fertige #FL2-Modul eine positive Klimabilanz auf.",
    "Dank der einmaligen Statik des Massivholzes und Standardmaße bei den Fensterausschnitten, lassen später weitere Raummodule anbauen. Die Familie wächst, das Haus auch.",
    "Hohe Lebensqualität, ausgelegt auf Generationen. Denn der massive Holzkern der #FL2-Module macht die Konstruktion äußerst robust und langlebig. Durch die natürliche Feuchteregulierung und seinen Duft sorgt das Fichtenholz aber auch für ein nachgewiesen gesünderes Klima im Innenraum und eine hervorragende Luftqualität, die seinen Bewohnern zugute kommt.",
    "Versetzen, übereinander Stapeln und das Anbauen weiterer Module. Der massive Holzkern aus 10 cm dickem Fichtenholz verleiht dem #FL2 eine unvergleichlich leistungsfähige Statik und Stabilität, die mit einer herkömmlichen Holzständerbauweise nicht zu erreichen ist.",
    "Geradlinig und minimalistisch. Bei der Auswahl von Oberflächen und Fassaden steht ein ganzes Sortiment an nachhaltigen Materialien zur Verfügung. Von funktional bis hin zu extravagant, wir beraten gerne."
  ],

  overnight: false,
  visitPossible: true,

  description: `Unser Team aus Schreinern, Designern, Bauingenieuren, Architekten, Baubiologen, Energieexperten und weiteren Handwerkern hat sich zum Ziel gesetzt, das Wohnen ins 21. Jahrhundert zu bringen. Deswegen verknüpfen wir ökologische und ökonomische Effizienz mit Stil und Qualität - und machen auf diese Weise nachhaltiges Wohnen attraktiv und erschwinglich.
  <br /><br />
  Wie schaffen wir das? Durch gezielte Reduktion, aber ohne Verzicht auf Wohnkomfort möchten wir deutlich mehr Lebensqualität erreichen. Ein funktionales und zeitloses Design in Kombination mit natürlichen Materialien macht es möglich. Hochwertige Werkstoffe bringen eine überdurchschnittlich lange Lebensdauer des #FL2 mit sich und sorgen damit für eine hohe Wertstabilität. Die Vision ist es, stilvoll, kompakt, qualitativ hochwertig und vor allem wohngesund zu bauen.
  <br /><br />
  Warum Massivholz? Holz ist der regenerative Baustoff der Zukunft. Während es wächst, sammelt es das CO2 aus unserer Atmosphäre. Deshalb können unsere Raummodule nach ihrer Produktion eine positive Klimabilanz mit mehreren Tonnen gebundenem Kohlenstoff aufweisen. Das schafft keine andere Bauart. ​
  <br /><br />
  Jetzt kostenlos unsere Infobroschüre mit allen Infos zu Ausstattung, Preise und Modelle&nbsp;<a href="https://info.mlab.design/fl2/?utm_source=listing&utm_medium=listing&utm_campaign=fl2-1&utm_id=tinyhousehelden&utm_term=tinyhousehelden" target="_blank">hier</a>&nbsp;anfordern. 
  <br /><br />
  <i>(Verfasser: mlab.design)</i>`
},
{
  name: 'LOVT Modulhaus GmbH',
  added: '09.12.2024',
  since: 2020,
  url: 'https://www.lovt.de',
  email: 'b2b@lovt.de',
  address: 'Erlenweg 10, 92703 Krummennaab',
  addressDisplayed:'92703 Krummennaab',
  coordinates: [49.85655226718956, 12.104616784706883],
  products: ["Modulhaus", "Sauna" ],
  prices: [ ['98700', 'Basismodell'], ["137000", "vollmöbliert"] ],

  length: '4m - 12m',
  useSpace: '15qm - 50qm',
  width: '4m',
  weight:'bis 3,5t',

  // Markieren Sie die Aussagen, die auf Sie zutreffen
  "length": "9,75m",
  "useSpace": " __25 m²",
  "width": "3,50m",
  "weight": "bis 3,5t",

  "customMade": true,
  "autarky": true,
  "modularDesign": false,
  "buildingShell": false,

  "features": ["Ökologische Materialien", "Zeitloses Design", "EH55 Standard", "Innovative Produkte"],
  "featureDesc": [
    "Wir nutzen fast ausschließlich ökologische Materialien aus Deutschland. Sowohl unser Ständerwerk, unsere Fenster, unsere Innen- und Außenwände, als auch unsere Dämmung bestehen aus Fichte. Einzig beim Dach und der Fensteraußenschale setzen wir auf Alu.",
    "Ein Haus mit großen Panoramascheiben, welches auch noch in 50 Jahren modern wirken wird",
    "All unsere Häuser sind baugenehmigungsfähig. Sie sind nach neusten Standards gedämmt (GeG) und verfügen über einen statischen Nachweis für ganz Deutschland.",
    "Von der Fußbodenheizung bis hin zum Echtsteinschiefer im Bad: Bei uns ist alles gut durchdacht und wohl überlegt."
  ],

  "overnight": true,
  "visitPossible": true,

  "description": "Wir bei LOVT - Essential House sind mehr als nur ein Modulhaus-Hersteller. Wir haben das ideale Tiny House entwickelt – ein Ort zum Leben oder Urlaub machen. Unser Fokus liegt auf minimalistischem Design und höchster Qualität. Dabei setzen wir nahezu ausschließlich auf Baustoffe aus Süddeutschland, wobei Fichte von den Innenwänden über die Dämmung bis zur Fassade maßgeblich zum Einsatz kommt. Verlässlichkeit und Nachhaltigkeit sind Eckpfeiler unserer Philosophie. Daher produzieren wir nicht nur in der heimischen Oberpfalz, sondern investieren auch einen Teil unseres Umsatzes in ein Urwaldprojekt in Rheinland-Pfalz. Diese Verbindung von Qualität, Lokalität und Umweltbewusstsein spiegelt sich in jedem Modulhaus von LOVT wider. Willkommen in einer neuen Ära des nachhaltigen und stilvollen Wohnens. <i>(Verfasser: LOVT)</i>"

},
{
  name: 'onoxo GmbH',
  added: '31.05.2024',
  since: 2017,
  url: 'http://www.onoxo-homes.de',
  email: 'info@onoxo-homes.de',
  address: 'Salierstr. 24, 72501 Gammertingen',
  addressDisplayed: '72501 Gammertingen',
  coordinates: [48.25662667628472, 9.222194183886321], 
  products: ["Modulhäuser", "kleines Fertighaus"],
  prices: [ ['100000', '40qm Ausbauhaus'], ['130000', 'Wohnfertig'] ],

  length: '12m',
  useSpace: '56,25qm',
  weight: 'über 3,5t',

  // Markieren Sie die Aussagen, die auf Sie zutreffen
  customMade: true,
  modularDesign: false,
  buildingShell: true,
  autarky: true,

  features: ["Standard Effizienhaus 40", "Komplettservice Bauantrag usw.", "Individuelle Grundrissplanung", "Jede Dachform möglich", "vollwertiges Fertighaus"],
  featureDesc: [
    "Ausführung als Standard Effizienhaus 40 Förderfähig ist möglich.",
    "Komplettservice Bauantrag, Erdarbeiten, Fundamente A-Z Projektabwicklung können Sie bei uns in Auftrag geben.",
    "Individuelle Grundrissplanung ist im Rahmen des Rasters möglich.",
    "Jede Dachform ist darstellbar / Anpassung an eventuelle Vorgaben eines Bebauungsplans.",
    "Die passende Bezeichnung für das was wir bieten ist: Vollwertiges kleines Fertighaus."  ],

  overnight: false,
  visitPossible: true,

  description: `Vermutlich sind Sie wie die meisten unserer Kunden auf der Suche nach einem ökologisch, hochwertig gebauten kleinen Fertighaus, das nur etwas größer ist als ein klassisches Tinyhaus. Vielleicht so 40, 50 oder 60 qm? Dann sind Sie bei onoxo home genau richtig!
  <br /><br />
  Wir sind seit 2017 im Markt und wir haben mit unserem Partner Kerngesund Haus inzwischen mehr als 80 coole kleine Häuser für unsere Kunden umgesetzt.
  <br /><br />
  zuhören - verstehen - planen - umsetzen
  <br /><br />
  Was unsere Kunden schätzen, ist, dass wir Sie bei Ihrem Wunsch nach einem kleinen Eigenheim von A - Z an die Hand nehmen. Vielleicht, ist es auch Ihr Ziel, dass Sie komfortabel, stressfrei und sicher zu Ihrem kleinen Eigenheim kommen. Dafür sind wir angetreten.
  <br /><br />
  Was Sie von uns bekommen, ist ein Haus, etwas größer als ein Tinyhaus. Ein Haus, welches durch Qualität, Nachhaltigkeit und Werthaltigkeit überzeugt. Ein Haus, das Ihren individuellen Ansprüchen entspricht, pfiffig und gemütlich. Ein Haus, das einfach Freude macht.
  <br /><br />
  Wir sind davon überzeugt, dass unsere Hausmodelle auch bei konsequenter Reduzierung auf das Wesentliche Ihre Erwartungen an ein zeitgemäßes Wohnkonzept übertreffen werden.
  <br /><br />
  Wir freuen uns auf Sie.
  <br /><br />
  <i>(Verfasser: Harry Keinath)</i>`
},
{
  name: 'Ineco GmbH',
  added: '11_02_2025',
  since: '',
  url: 'https://ineco.construction/',
  email: 'info@ineco.construction',
  address: 'Jahnplatz 6, 33602 Bielefeld',
  addressDisplayed:'33602 Bielefeld',
  coordinates: [52.02369270325928, 8.533581440095325],
  products: [],
  prices: [ ['?', ''] ],

  length: '',
  useSpace: '',
  width: '',
  weight:'',

  // Markieren Sie die Aussagen, die auf Sie zutreffen
  customMade: '',
  modularDesign: '',
  buildingShell: '',
  autarky: '',
  
  features:[
    'keine Angaben',
    '',
    '',
    '',
    ''
  ],
  featureDesc:[
    "",
    "",
    "",
    "",
    ""
  ],

  overnight: false,
  visitPossible: false,

  description: ``
}

];



const sample = {
  name: 'XXXXX',
  added: '31_10_2021',
  since: 2020,
  url: 'www_xxxx_de',
  email: 'info@tiny-life.de',
  address: 'Birkenstr. 28, 85467 Niederneuching',
  addressDisplayed:'85467 Niederneuching',
  coordinates: [48.25209464400832, 11.830495512026767],
  products: [products.thow, products.modul, products.garden],
  prices: [ ['60000', ''] ],

  length: 'ab 6m',
  useSpace: '13m² - 80m²',
  width: '2,55m - 3,50m',
  weight:'bis 3,5t',

  // Markieren Sie die Aussagen, die auf Sie zutreffen
  customMade: true,
  modularDesign: false,
  buildingShell: false,
  autarky: false,
  
  features:[
    'keine Angaben',
    '',
    '',
    '',
    ''
  ],
  featureDesc:[
    "",
    "",
    "",
    "",
    ""
  ],

  overnight: false,
  visitPossible: false,

  description: `Herzlichen Willkommen bei Tiny Life.<br><br
  Du bist auf der Suche nach einem individuellen Tiny- oder Modulhaus-dann bist Du hier genau richtig!"
  Unsere Modulhäuser sowie unsere Tiny Häuser auf Rädern
  richten sich an Menschen die ihren Lebensstil auf das Wesentliche reduzieren wollen und dabei nicht auf den Komfort eines hochwertig gebauten Gebäudes verzichten möchten.
  Das intelligente und flexible  Konzept hat viele Anwendungs- möglichkeiten, und legt vorallem Wert auf ein anspruchsvolles Design als Eigenheim, als Ferienhaus, oder als Rückzugsort für Freizeit oder Arbeit. Wir gestalten unsere Tiny Life Häuser nach dem Motto: Great life. Tiny Footprint. - in dem wir einen unabhängigen und nachhaltigen Wohnraum für dieZukunft schaffen. Entscheide Dich jetzt für mehr Freiheit und Flexibilät!
  
  ​<br><br>
  <i>(Verfasser: Tiny Life)</i>`
}
void sample;

/*team@techtinyhouse.de WERKSTATT@WOHNWAGON.AT  mail@dreamwood.info  peter@rolling-tiny-house.de  
info@bohrdesign.de  info@bock-tischlerei.de  info@tiny-house-manufaktur.de  info@koersmann.de  
info@kleinernomade.org  verkauf@zirkuswagenbau.net  info@rollinghomes.de  erlebnis@hausfloss.de  info@rundwagen.de  
info@tiny-house-allgaeu.de  tinyhouses@web.de  info@holzdesign-hegselmann.de  koopmann.christian@gmx.de  
tinyhouse@diekmann-schreinerei.de  servus@nimme.net  dieterpuhane@outlook.de 

*/

const emailTemplate= `Liebes XXX Team,

vielen Dank für den Eintrag auf unserer Website. Dieser ist nun online und kann auf https://www.tiny-house-helden.de/hersteller/ eingesehen werden. Sollten sich bei der Eintragung Fehler eingeschlichen haben, sagen Sie bitte Bescheid. 

Denken Sie bitte daran, uns gemäß der auf unserer Website stehenden Vereinbarung zu verlinken. Weiterhin erhalten Sie eine Rechnung von 30€, sobald Sie sich Ihren Eintrag angeschaut haben.

Wir wünschen Ihnen zahlreiche Neukunden für Ihr Unternehmen :) 

Viele Grüße
Michael von Tiny House Helden`;

void emailTemplate;